import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Typography from "../styles/Typography"

import "../styles/nav-styles.scss"

const NavStyles = styled.nav`
  /* background: #e8e8dc; */
  /* margin-bottom: 3rem; */
  /* width: 100%; */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 2rem;
  z-index: 1020;
  text-transform: uppercase;

  a {
    text-decoration: none;
  }

  // TODO add hover effect to change link color

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* align-content: center; */

    /* justify-content: center; */
    list-style: none;
  }

  li {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    a {
      /* padding: 1rem; */
      display: block;
    }

    &:hover {
      /* background-color: #555; */
    }
  }

  // mobile nav toggle button
  .toggle-button {
    position: absolute;
    top: 1.3rem;
    right: 1.8rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;

    .bar {
      height: 3px;
      width: 100%;
      background-color: black;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 600px) {
    .toggle-button {
      display: flex;
    }

    .navbar-links {
      display: none;
      width: 100%;
    }

    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }

    .navbar-links ul {
      flex-direction: column;
      width: 100%;
    }

    .navbar-links li {
      text-align: center;
    }

    .navbar-links li a {
      padding: .5rem 1rem;
    }

    .navbar-links.active {
      display: flex;
    }
  }
`;

export default function Nav() {

  const handleToggle = (e) => {
    document.getElementsByClassName('navbar-links')[0].classList.toggle('active')
    document.getElementById('hamburger-icon').classList.toggle('active')
  }

  const closeNav = () => {
    const links = document.getElementsByClassName('navbar-links')[0]
    const hamburger = document.getElementById('hamburger-icon')

    if (links.classList.contains("active")) {
      links.classList.remove("active")
    }

    if (hamburger.classList.contains("active")) {
      hamburger.classList.remove("active")
    }
  }

  return (
    <nav className="navbar">
      <Typography />
      <div className="brand-title" onClick={closeNav}>
        <Link to="/">Taylor-Hill Properties</Link>
      </div>

      {/* Mobile nav hamburger menu */}
      <a href="#" className="toggle-button" id="hamburger-icon" aria-label="Open the menu" onClick={handleToggle}>
        <span className="bar line line-1"></span>
        <span className="bar line line-2"></span>
        <span className="bar line line-3"></span>
      </a>
      
      <div className="navbar-links">
        <ul onClick={closeNav}>
          <li><Link to="/properties">Properties</Link></li>
          <li><Link to="/apartments">All Floorplans</Link></li>
          {/* <li><Link to="/service-requests">Service Requests</Link></li> */}
          <li><Link to="/contact">Contact</Link></li>
          <li>
            <a 
              href="https://passport.appf.io/sign_in?idp_type=tportal&vhostless=true"
              target="_blank"
              className="pay-online-btn"
            >
                Pay Online
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}