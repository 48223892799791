/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import "normalize.css";
import Nav from "./Nav"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // return (
  //   <>
  //     <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
  //     <div
  //       style={{
  //         margin: `0 auto`,
  //         maxWidth: 960,
  //         padding: `0 1.0875rem 1.45rem`,
  //       }}
  //     >
  //       <main>{children}</main>
  //       <footer
  //         style={{
  //           marginTop: `2rem`,
  //         }}
  //       >
  //         © {new Date().getFullYear()}, Designed + Developed by
  //         {` `}
  //         <a href="#">Tyler Taylor</a>
  //       </footer>
  //     </div>
  //   </>
  // )

  return (
    <div className="layout">
      <div className="site">
        <Nav />
        <div className="site-content">
          {children}
        </div>  
        <Footer />
      </div>
    </div>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
