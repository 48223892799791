import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="company-details">
        <div className="address detail">
          <h2>Location</h2>
          <p>333 Washington Blvd</p>
          <p>Abilene, TX 79601</p>
        </div>

        <div className="office-hours detail">
          <h2>Office Hours</h2>
          <p>Monday - Friday: 8:30 am - 5:00 pm</p>
        </div>

        <div className="contact detail">
          <h2>Contact</h2>
          <div className="phone">
            <p><a href="tel:+1-325-672-3200"><span className="icon">☎️</span>: (325) 672-3200</a></p>
            <p><a href="tel:+1-325-672-3209"><span className="icon">📠</span>: (325) 672-3209</a></p>
          </div>
        </div>
      </div>
      
      <div className="copywrite">
        <p>
          &copy;{new Date().getFullYear()} 
          <a href="https://taylor-hill.com">Taylor-Hill Properties</a>

          | Site by <a href="#">Tyler Taylor</a>
        </p>
      </div>
    </footer>
  )
}